import { computed } from 'vue';
import type { RouteRecordRaw } from 'vue-router';

import { useGlobalI18n } from '@abaninja/util-i18n';

const {
  global: { t },
} = useGlobalI18n();

export const routes: RouteRecordRaw[] = [
  {
    path: 'documents',

    children: [
      {
        path: '',
        name: 'documents',
        meta: {
          title: computed(() => t('messages.documents')),
        },

        component: () => import('../DocumentsIndex.vue'),
      },
      {
        path: 'invoice/:uuid',
        name: 'invoice',
        component: () => import('../InvoiceIndex.vue'),
        props: true,
      },
      {
        path: 'quote/:uuid',
        name: 'quote',
        component: () => import('../QuoteIndex.vue'),
        props: true,
      },
      {
        path: 'contract-note/:uuid',
        name: 'contract-note',
        component: () => import('../ContractNoteIndex.vue'),
        props: true,
      },
      {
        path: 'credit-note/:uuid',
        name: 'credit-note',
        component: () => import('../CreditNoteIndex.vue'),
        props: true,
      },

      // fallback
      {
        path: 'document/:uuid',
        name: 'document',
        component: () => import('../DocumentIndex.vue'),
        props: true,
      },
    ],
  },
];
