import { useNavigatorLanguage } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { createRouter, createWebHistory, type RouteLocationNormalizedGeneric } from 'vue-router';

import { useCustomerPortalSettingsStore } from '@abaninja/data-access-customer-portal-customer-portal';
import { useRouterState } from '@abaninja/util-router';

import { routes } from './routes';
import { switchLanguage } from '../plugins/i18n';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

function setHashFromRouteParams(to: RouteLocationNormalizedGeneric) {
  const hash = to.params.hash ?? to.redirectedFrom?.params.hash;

  if (typeof hash === 'string') {
    sessionStorage.setItem('hash', hash);

    return true;
  }

  return false;
}

// eslint-disable-next-line complexity
router.beforeEach(async (to) => {
  const { isLoading } = storeToRefs(useRouterState());
  isLoading.value = true;

  if (to.meta.needsAuth) {
    setHashFromRouteParams(to);

    if (!sessionStorage.getItem('hash')) {
      // redirect to not found page
      return { name: '404' };
    }

    // hash is set so load default settings
    const { getEntity, entity } = useCustomerPortalSettingsStore();
    if (!entity.value) {
      await getEntity();
    }

    if (entity.value?.language) {
      switchLanguage(entity.value.language);
    }
  } else {
    const { language } = useNavigatorLanguage();

    const locale = language.value?.substring(0, 2) as SupportedLocale | undefined;

    if (locale) {
      switchLanguage(locale);
    }
  }

  return true;
});

router.afterEach(() => {
  const { isLoading, hasLoaded } = storeToRefs(useRouterState());
  isLoading.value = false;
  hasLoaded.value = true;
});

export default router;
