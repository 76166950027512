import { createInjectionState, watchImmediate } from '@vueuse/core';
import { onUnmounted, computed, type Ref } from 'vue';

import { usePageLoaderContext } from './PageLoader.use';

const [providePageLoader, injectPageLoader] = createInjectionState(usePageLoaderContext);

export { providePageLoader };

export function injectPageLoaderWithDefaultValue() {
  return injectPageLoader() ?? {
    showLoader: computed(() => false),
  };
}

function injectPageLoaderOrThrow() {
  const context = injectPageLoader();

  if (!context) {
    throw new Error(
      'Please call `providePageLoader` on the appropriate parent component',
    );
  }

  return context;
}

export const usePageLoader = (isLoading: Ref<boolean>) => {
  const pageLoader = injectPageLoaderOrThrow();

  const id = Symbol('pageLoader');

  watchImmediate(isLoading, (value) => {
    if (value) {
      pageLoader.setItem(id, true);
    } else {
      pageLoader.deleteItem(id);
    }
  });

  onUnmounted(() => {
    pageLoader.deleteItem(id);
  });
};
