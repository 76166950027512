import { httpClient } from '@abaninja/util-http-client';
import { createCancellablePromise } from '@abaninja/util-store';

import type { Settings } from '../@types';

export function getCustomerPortalSettings() {
  return createCancellablePromise(
    ({ signal }) => httpClient.get<Payload<Settings>>('/customer-portal/v2/settings', { signal }),
  );
}
