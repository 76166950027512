import type { RouteRecordRaw } from 'vue-router';

import { routes as documentRoutes } from '@abaninja/page-customer-portal-documents';

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'index',
    component: () => import('../../layout/Default.vue'),
    redirect: { name: 'documents' },
    meta: {
      needsAuth: true,
    },
    children: [
      ...documentRoutes,
    ],
  },
  {
    path: '/',
    children: [
      {
        path: 'portal/:hash',
        // props: true,
        redirect: { name: 'index', params: undefined },
        children: [],
      },
      {
        path: '404',
        component: () => import('../../layout/MainView.vue'),
        children: [
          {
            path: '',
            name: '404',
            component: () => import('../../pages/NotFound.vue'),
          },
        ],
      },
      {
        path: ':pathMatch(.*)*',
        redirect: (to) => ({
          name: '404', query: {
            toPath: to.fullPath,
          },
        }),
      },
    ],
  },

];
