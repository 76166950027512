import { createInjectionState, useConfirmDialog } from '@vueuse/core';

export type Data = {
  message?: string;
  noBtnText?: string;
  title?: string;
  yesBtnText?: string;
}

const [provideConfirmationDialog, injectConfirmationDialog] = createInjectionState(
  useConfirmDialog<Data, never, never>,
);

export { provideConfirmationDialog };

export function useConfirmationDialog() {
  const context = injectConfirmationDialog();

  if (!context) {
    throw new Error(
      'Please call `provideConfirmationDialog` on the appropriate parent component',
    );
  }

  return context;
}
