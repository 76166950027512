import axios, { type AxiosInstance, type CreateAxiosDefaults } from 'axios';

import {
  requestBearerTokenInterceptor,
  requestParamsSerializerInterceptor,
} from './interceptors';

export let httpClient: AxiosInstance = axios;

/**
 * root route calls
 *
 * @param defaults
 */
export function createHttpClient(defaults: CreateAxiosDefaults) {
  httpClient = axios.create(defaults);

  const requestBearerTokenInterceptorId = httpClient.interceptors.request.use(requestBearerTokenInterceptor);

  httpClient.interceptors.request.use(requestParamsSerializerInterceptor);

  return {
    requestBearerTokenInterceptorId,
  };
}
