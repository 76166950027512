<template>
  <v-dialog :model-value="isRevealed" persistent>
    <v-card :text="revealData?.message" :title="revealData?.title">
      <v-card-actions>
        <v-btn variant="tonal" @click="cancel">
          {{ revealData?.noBtnText ?? t('messages.no') }}
        </v-btn>
        <v-btn color="primary" variant="flat" @click="confirm">
          {{ revealData?.yesBtnText ?? t('messages.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VBtn, VCard, VCardActions, VDialog } from 'vuetify/components';

import { type Data, useConfirmationDialog } from './ConfirmationDialog.context';

const { t } = useI18n();

const { isRevealed, confirm, onReveal, cancel } = useConfirmationDialog();

const revealData = ref<Data>();

onReveal((data: Data) => {
  revealData.value = data;
});

</script>
