<template>
  <v-app>
    <app-notifications />
    <confirmation-dialog />

    <router-view />

    <!-- Loader overlay -->
    <v-fade-transition>
      <div v-show="!hasLoaded" class="loader-wrapper">
        <loader />
      </div>
    </v-fade-transition>
  </v-app>
</template>

<script lang="ts" setup>

import { syncRefs, watchImmediate } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { useTheme } from 'vuetify';

import { type Settings, useCustomerPortalSettingsStore } from '@abaninja/data-access-customer-portal-customer-portal';
import { AppNotifications, useProvideNotificationContext } from '@abaninja/feature-notification';
import { useThemeColorMode } from '@abaninja/feature-theme';
import { ConfirmationDialog, provideConfirmationDialog } from '@abaninja/ui-dialog';
import { Loader, providePageLoader } from '@abaninja/ui-loader';
import { useRouterState } from '@abaninja/util-router';

const { hasLoaded } = storeToRefs(useRouterState());

// Set current theme
const theme = useTheme();
const themeColorMode = useThemeColorMode();
syncRefs(themeColorMode.currentTheme, theme.global.name);

// global providers
providePageLoader();
useProvideNotificationContext();
provideConfirmationDialog();

// loading happens in the router so be patient
const { entity } = useCustomerPortalSettingsStore();

function setPrimaryColorFromSettings(value: Settings) {
  if (value.colors.primary) {
    theme.themes.value.light.colors.primary = value.colors.primary;
    theme.themes.value.dark.colors.primary = value.colors.primary;
  }
  if (value.colors.onPrimary) {
    theme.themes.value.light.colors['on-primary'] = value.colors.onPrimary;
    theme.themes.value.dark.colors['on-primary'] = value.colors.onPrimary;
  }
}

function setSecondaryColorFromSettings(value: Settings) {
  if (value?.colors.secondary) {
    theme.themes.value.light.colors.secondary = value?.colors.secondary;
    theme.themes.value.dark.colors.secondary = value?.colors.secondary;
  }
  if (value?.colors.onSecondary) {
    theme.themes.value.light.colors['on-secondary'] = value?.colors.onSecondary;
    theme.themes.value.dark.colors['on-secondary'] = value?.colors.onSecondary;
  }
}

watchImmediate(entity, (value) => {
  if (!value) {
    return;
  }
  setPrimaryColorFromSettings(value);
  setSecondaryColorFromSettings(value);
});
</script>

<style lang="postcss" scoped>
.loader-wrapper{
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--v-theme-background));
  z-index: 2000;
}
</style>
