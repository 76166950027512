import { required } from '@vee-validate/rules';
import { configure, defineRule } from 'vee-validate';

import { useGlobalI18n, interpolate } from '@abaninja/util-i18n';

export function createVeeValidate() {
  const { global: { t } } = useGlobalI18n();

  defineRule('required', required);
  configure({
    generateMessage: (context) => interpolate(
      t(`rules.${context.rule?.name}`, { field: context.label ?? t(`messages.${context.name}`), value: context.value }),
      { params: context.rule?.params || [] },
    ),
  });
}
