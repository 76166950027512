
import { createApp } from 'vue';

import { createHttpClient, httpClient } from '@abaninja/util-http-client';

import App from './App.vue';
import 'vuetify/styles';
import 'unfonts.css';
import './scss/base.scss';
import { i18n } from './plugins/i18n';
import { pinia } from './plugins/pinia';
import { initSentry } from './plugins/sentry';
import { unhead } from './plugins/unhead';
import { createVeeValidate } from './plugins/vee-validate';
import { vuetify } from './plugins/vuetify';
import router from './router';

const app = createApp(App);

// init plugins
app.use(router);
app.use(vuetify);
app.use(i18n);
app.use(unhead);
app.use(pinia);

createVeeValidate();

const { requestBearerTokenInterceptorId } = createHttpClient({
  baseURL: `${import.meta.env.VITE_API_URL}`,
});

httpClient.interceptors.request.eject(requestBearerTokenInterceptorId);

httpClient.interceptors.request.use((config) => {
  // do not prefix basics with admin-tool
  config.headers['X-Abaninja-Auth'] = sessionStorage.getItem('hash');

  return { ...config };
});

initSentry(app, router);

app.mount('#root');
